import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Slider from "react-slick"
import {
  BreadCrumb,
  Background,
  Arrow,
  Badge,
  SectionPageTitle,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import IndustryHeroBg from "../components/Image/IndustryHeroBg"
import { Container, Section } from "../components/SectionTags"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import BreakpointUp from "../components/Media/BreakpointUp"

import VideoBlock from "../components/VideoBlock"
import MVVideoModal from "../components/MVVideoModal"

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px -15px 0 -15px;
  ${BreakpointUp.xl`
    margin: 60px -15px 0 -15px;
  `}
`
const GridItem = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  display: ${(props) => props.display};
  ${BreakpointUp.md`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.lg`
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
`

const HeroSection = styled.div`
  background:#D7E7F5;
  padding-bottom:30px;
  padding-top:90px;
	@media (min-width: 1024px){
		padding-top:120px;		
	}
`
const BadgeList = styled.div`
  flex-wrap: wrap;
  margin:0 -5px;
  display:none;
  ${BreakpointUp.xl`
    display: flex;
  `}
`
const BadgeItem = styled.div`
  padding:0 5px;
`
const BadgeSlider = styled.div`
  ${BreakpointUp.xl`
    display:none;
  `}
`
const VideoList = styled.div`
`
const VideoCard = styled.div`
  display: ${(props) => props.display};
  position: relative;
  z-index: 2;
  border: 1px solid #DEDEDE;
  background-color:#fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom:30px;
  height: calc(100% - 30px);
  & .mvVideoContainer {
    & iframe {
      display: none;
    }
  }
  & .mvVideoContainer .video-js {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`
const VideoHeader = styled.div`
  margin:0;
  padding:0;
`
const VideoBody = styled.div`
  padding: 20px 30px;
`
const VideoTitle = styled.h2`
font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  line-height: 28px;
`
const NoResult = styled.h2`
  font-family: 'UniNeueBold', sans-serif;
  width: 100%;
  text-align: center;
  font-size: 22px;
  line-height: 32px;
`
class Videos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentCategory: "Featured Videos",
      videos: this.props.data.allContentfulVideo.edges,
      isVideoModalOpen: false,
      currentVideo: { src: "", type: "" },
    }
    this.handleCategory = this.handleCategory.bind(this)
    this.handleVideo = this.handleVideo.bind(this)
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
  }
  handleCategory(data) {
    this.setState({
      currentCategory: data,
    })
  }
  playVideo() {
    this.refs.vidRef.play()
  }
  openVideoModal(url) {
    this.setState({
      currentVideo: {
        type: "video/youtube",
        src: url,
      },
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  handleVideo(url) {
    this.openVideoModal(url)
  }
  render() {
    const categories = [
      "Featured Videos",
      "Electronic Repair",
      "Mechanical Repair",
      "Servo Repair",
      "Hydraulic Repair",
      "Imaging Repair",
    ]
    const { currentCategory, videos } = this.state
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          },
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1
          },
        },
      ],
    }
    return (
      <Layout>
        <Seo title="Watch Technical Videos - Advanced Electronic Services" description="Technical Videos - Discover the AES video resources to understand the process, delivery, warranty and much more. Contact us today for more information" />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section xpb="80px" mpb="60px" pt="0" pb="30px">
          <MVVideoModal
            isOpen={this.state.isVideoModalOpen}
            autoPlay
            src={this.state.currentVideo.src}
            type={this.state.currentVideo.type}
            controls
            onClose={this.closeVideoModal}
          />
          <Background right="0" zIndex="0" height="inherit"><IndustryHeroBg /></Background>
          <HeroSection>
            <Container>
              <BreadCrumb>
                <Link to="/"><span>Home</span></Link> Video
              </BreadCrumb>
              <SectionPageTitle>Technical Videos!</SectionPageTitle>
              <BadgeList>
                {categories.map((item, i) => {
                  return (
                    <BadgeItem>
                      <Badge
                        key={i}
                        onClick={() => this.handleCategory(item)}
                        bg={currentCategory === item ? "#FAB702" : "#FFFFFF"}
                        border={
                          currentCategory === item ? "1px solid #C3E3FF" : "none"
                        }
                        color={
                          currentCategory === item ? "#FFFFFF" : "rgba(0,0,0,.6)"
                        }
                      >
                        {item}                       
                        <Arrow
                          display={currentCategory === item ? "block" : "none"}
                        />
                      </Badge>
                    </BadgeItem>
                  )
                })}
              </BadgeList>
              <BadgeSlider>
                <Slider {...settings}>
                  {categories.map((item, i) => {
                    return (
                      <BadgeItem>
                        <Badge
                          key={i}
                          onClick={() => this.handleCategory(item)}
                          bg={currentCategory === item ? "#FAB702" : "#FFFFFF"}
                          border={
                            currentCategory === item
                              ? "1px solid #C3E3FF"
                              : "none"
                          }
                          color={
                            currentCategory === item
                              ? "#FFFFFF"
                              : "rgba(0,0,0,.6)"
                          }
                        >
                          {item}
                        </Badge>
                      </BadgeItem>
                    )
                  })}
                </Slider>
              </BadgeSlider>
            </Container>
          </HeroSection>
          <VideoList>
            <Container>
              <Grid>
                {videos.map((item, i) => {
                  if (currentCategory === "Featured Videos") {
                    return (
                      <GridItem>                        
                        <VideoCard display="block">
                          <VideoHeader>
                            <VideoBlock
                              action={() => this.handleVideo(item.node.videoUrl)}
                              video={item.node.videoUrl}
                            />
                          </VideoHeader>
                          <VideoBody>
                            <VideoTitle>{item.node.title}</VideoTitle>
                          </VideoBody>
                        </VideoCard>
                      </GridItem>
                    )
                  } else {
                    return (
                      <NoResult>Video Coming Soon !!</NoResult>                      
                    )
                  }
                })}
              </Grid>
            </Container>
          </VideoList>
        </Section>
        <EmergencyEstimateCTA />
      </Layout>
    )
  }
}

export default Videos

export const pageQuery = graphql`
  query VideosPageQuery {
    allContentfulVideo(sort: { fields: createdAt }) {
      edges {
        node {
          title
          videoUrl
          category {
            serviceName
          }
          updatedAt(fromNow: true)
        }
      }
    }
  }
`
